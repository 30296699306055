<template>
  <v-select
    ref="vSelect"
    v-bind="omit(context.attributes)"
    :class="`formulate-input-element fs__select`"
    :placeholder="placeholder"
    :options="internalOptions || []"
    :filterable="false"
    :searchable="true"
    :clearable="false"
    :no-drop="columnCount === 0"
    :select-on-tab="true"
    :reset-on-options-change="false"
    :close-on-select="true"
    :deselect-from-dropdown="false"
    :get-option-key="getOptionKey"
    :label="labelKey"
    :data-type="context.type"
    :value="selectedOption"
    @input="vSelectionChanged"
  >
    <template #no-options="{}">
      <span></span>
    </template>
    <template #search="search">
      <div
        tabindex="0"
        class="fs__search vs__search"
        v-bind="omit(search.attributes, removeAttributes)"
        v-on="search.events"
      >
        <flex-select-option
          :option="selectedOption"
          :label-field-names="labelFieldNames"
        />
      </div>
    </template>
    <template #selected-option>
      <div tab-index="1" class="dummy"></div>
    </template>
    <template #option="option">
      <flex-select-option
        :needs-arrow-space="true"
        :option="option"
        :label-field-names="labelFieldNames"
        :columns-are-wrapping="itemsAreWrapping"
      />
    </template>
  </v-select>
</template>
<script>
import vSelect from 'vue-select'
import _, { some, every, first, all, omit } from 'lodash'
import FlexSelectOption from './FlexSelectOption.vue'

export default {
  name: 'FlexSelect',
  components: {
    'v-select': vSelect,
    'flex-select-option': FlexSelectOption,
  },
  props: {
    context: {
      type: [Object, Array],
      required: true,
      default: () => ({}),
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionMapper: {
      type: Function,
      required: false,
      default: (option) => option,
    },
    valueKey: {
      type: String,
      required: false,
      default: 'value',
    },
    labelFieldNames: {
      type: Array,
      required: false,
      default: () => ['label'],
    },
  },
  data() {
    return {
      itemsAreWrapping: false,
      isMounted: false,
      internalOptions: [],
      selectedOptionKey: undefined,
      previousModel: undefined,
      removeAttributes: [
        'aria-autocomplete',
        'aria-labelledby',
        'aria-controls',
      ],
    }
  },
  computed: {
    placeholder() {
      return this.context && this.context.placeholder
        ? this.context.placeholder
        : undefined
    },
    columnCount() {
      return this.labelFieldNames.length || 0
    },
    selectedOption() {
      return this.internalOptions.find(
        (option) => option.key === this.selectedOptionKey
      )
    },
    labelKey() {
      return this.labelFieldNames.length ? this.labelFieldNames[0] : 'label'
    },
  },
  watch: {
    context: {
      immediate: true,
      handler(newContext, oldContext) {
        if (newContext) {
          const newOptions = newContext.options
          const oldOptions = oldContext ? oldContext.options : []
          if (newOptions && !_.isEqual(newOptions, oldOptions)) {
            this.processOptions(newOptions)
          }

          const model = newContext.model
          const prevModel = this.previousModel
          if (model !== prevModel) {
            if (Object.hasOwn(model, this.valueKey)) {
              const selectedKey = model[this.valueKey]
              if (selectedKey !== this.selectedOptionKey) {
                this.selectedOptionKey = selectedKey
                this.previousModel = selectedKey
              }
            } else {
              this.selectedOptionKey = undefined
              this.prevModel = undefined
            }
          }
        }
        if (this.isMounted) {
          this.sizeUpdate()
        }
      },
    },
    selectedOptionKey(newKey, oldKey) {
      if (newKey !== oldKey) {
        const originalOptions = this.context.options || []
        const selectedOption = originalOptions.find((opt) => {
          if (opt[this.valueKey] === newKey) {
            return true
          }
        })
        this.context.rootEmit('input', selectedOption)
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.sizeUpdate)
    this.sizeUpdate()
    this.isMounted = true
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.sizeUpdate)
  },
  methods: {
    omit,
    getOptionKey(option) {
      if (
        typeof option === 'object' &&
        Object.hasOwn(option, 'key') &&
        option.key != null
      ) {
        return option.key
      } else {
        try {
          return JSON.stringify(option)
        } catch (e) {
          throw new Error(
            `[flex-select warn]: Could not stringify option ` +
              `options need a unique key specified by the valueKey prop ` +
              `or Arrays of primitives must all be unique values and json serializable .`
          )
        }
      }
    },
    hasValidKeys(options) {
      const hasKeys = !some(options, (opt) => {
        return (
          Object.hasOwn(opt, this.valueKey) &&
          all(Object.hasOwn(opt, this.labelFieldNames))
        )
      })
      return hasKeys
    },
    /**
     * Check if options is an array and if so that it only contains objects
     * @param options
     */
    isOnlyObjects(options) {
      return every(options, (opt) => {
        return typeof opt === 'object' && opt != null
      })
    },
    /**
     * Check if options is an array and if so that it only contains primitive values like string, boolean, number, null etc
     * @param options
     */
    isOnlyPrimitivesArray(options) {
      if (!Array.isArray(options)) return false
      return every(options, (opt) => {
        return (
          (typeof opt !== 'object' && typeof opt !== 'function') || opt === null
        )
      })
    },
    validateOptions(options) {
      if (!options) {
        throw new Error(
          `[flex-select error]: Options are required and must be an array of primitives or an object of objects.`
        )
      }

      if (Array.isArray(options) && options.length === 0) {
        return {
          isEmpty: true,
          isPrimitiveArray: false,
          isObjectOptions: false,
        }
      }

      const isValidType =
        Array.isArray(options) ||
        (typeof options === 'object' && options != null)
      if (!isValidType) {
        throw new Error(
          `[flex-select error]: Options must be an array or object and cannot be undefined or null.`
        )
      }

      const objectsOnly = this.isOnlyObjects(options)
      const isOnlyPrimitivesArray = this.isOnlyPrimitivesArray(options)

      if (objectsOnly) {
        return {
          isEmpty: false,
          isPrimitiveArray: false,
          isObjectOptions: true,
        }
      }

      if (isOnlyPrimitivesArray) {
        return {
          isEmpty: false,
          isPrimitiveArray: true,
          isObjectOptions: false,
        }
      }

      throw new Error(
        `[flex-select error]: Options must be an object of ojects, or an array of primitives.`
      )
    },
    processOptions(newOptions) {
      if (!newOptions) {
        this.internalOptions = []
        return
      }

      const validateResult = this.validateOptions(newOptions)

      if (validateResult.isEmpty) {
        this.internalOptions = []
        return
      }

      if (validateResult.isPrimitiveArray) {
        this.internalOptions = newOptions.map((option) => {
          return {
            key: option,
            label: option,
          }
        })
        return
      }

      const processedOptions = newOptions
        .map(this.optionMapper)
        .filter((opt) => {
          const missingValueKey = !Object.hasOwn(opt, this.valueKey)
          if (missingValueKey) {
            console.warn(
              `[flex-select warn]: Option is missing value key: '"${this.valueKey}"'' and will be ignored.`
            )
            return false
          }
          const missingLabelNames = this.labelFieldNames.some(
            (label) => !Object.hasOwn(opt, label)
          )
          if (missingLabelNames) {
            console.warn(
              `[flex-select warn]: Option is missing one of the labelFieldNames: '"${this.labelFieldNames.join(
                ', '
              )}"'' and will be ignored.`
            )
            return false
          }
          return true
        })
        .map((option) => {
          const missingValueKey = !Object.hasOwn(option, this.valueKey)
          const valueKeyValue = missingValueKey
            ? undefined
            : option[this.valueKey]

          const newOption = {
            key: valueKeyValue,
          }

          this.labelFieldNames.forEach((labelKey) => {
            const labelValue = option[labelKey]
            newOption[labelKey] = labelValue
          })

          return newOption
        })
      this.internalOptions = processedOptions
    },
    vSelectionChanged(option) {
      if (option) {
        const originalOptions = this.context.options || []
        const selectedOption = originalOptions.find((opt) => {
          if (opt[this.valueKey] === option.key) {
            return true
          }
        })
        if (selectedOption) {
          this.selectedOptionKey = selectedOption[this.valueKey]
        }
      }
    },
    /**
     * Checks all the items in a flex container (top level children) to see if any have
     * wrapped by checking if their offsetTop is greater than the first item's offsetTop
     * @param container an element containing flex items
     */
    checkFlexWrap(container) {
      const items = container.children
      let hasWrapped = false
      if (items.length > 1) {
        const firstItemTop = items[0].offsetTop
        for (let i = 1; i < items.length; i++) {
          if (items[i].offsetTop > firstItemTop) {
            hasWrapped = true
            break
          }
        }
      }
      return hasWrapped
    },
    /**
     * Determines if the columns in the vue select search slot or the dropdown are wrapping
     * and if so sets itemsAreWrapping to true and false if not.  This is used to add a class conditionally
     * that the css can use to change styling based on wrapped or not wrapped
     */
    sizeUpdate() {
      if (this.$refs.vSelect && this.$refs.vSelect.$el) {
        const element = this.$refs.vSelect.$el

        const dropDownOptions = Array.from(
          element.querySelectorAll('.vs__dropdown-option')
        )

        const firstDropDownOption = first(dropDownOptions)
        if (!firstDropDownOption) return

        const flexContainer = first(
          firstDropDownOption.querySelectorAll('.fs__options_container_inner')
        )
        if (flexContainer) {
          if (this.checkFlexWrap(flexContainer)) {
            this.itemsAreWrapping = true
          } else this.itemsAreWrapping = false
        } else {
          this.itemsAreWrapping = false
        }
      }
    },
  },
}
</script>
