import { cloneDeep } from 'lodash'

const DEFAULT_SECTION_STATE = {
  giftSelection: {
    drawerOpen: true,
    selectedPlan: null,
    selectedGift: null,
    giftNote: '',
  },
  contactInformation: {
    drawerOpen: true,
    firstName: '',
    lastName: '',
    email: '',
    gifter_date_of_birth: '',
  },
  shippingInformation: {
    drawerOpen: true,
  },
  paymentInformation: {
    drawerOpen: true,
    selectedPaymentOption: null,
    selectedPaymentMethod: null,
    savePaymentDetails: true,
  },
}

const state = () => ({
  giftableMemberships: [],
  visibleMemberships: [],
  loading: false,
  loadingCount: 0,
  sectionState: cloneDeep(DEFAULT_SECTION_STATE),
})

const mutations = {
  SET_SUBSCRIPTION_GIFTING_LOADING(state, value) {
    if (value) {
      state.loading = true
      state.loadingCount += 1
    } else if (state.loadingCount <= 1) {
      state.loading = false
      state.loadingCount = 0
    } else {
      state.loadingCount -= 1
    }
  },
  SET_GIFTABLE_MEMBERSHIPS(state, value) {
    state.giftableMemberships = value
    state.visibleMemberships = value
      .filter((membership) => membership.has_visible_gift_term)
      .sort(
        (a, b) =>
          (a.gifting_position === null) - (b.gifting_position === null) ||
          a.gifting_position - b.gifting_position
      )
  },
  SET_SECTION_STATE(state, { section, value }) {
    state.sectionState[section] = value
  },
  CLEAR_SECTION_STATE(state) {
    state.sectionState = cloneDeep(DEFAULT_SECTION_STATE)
  },
  SET_SECTION_DRAWER_OPEN(state, { section, value }) {
    state.sectionState[section].drawerOpen = value
  },
}

const actions = {
  setSubscriptionGiftingLoading({ commit }, value) {
    commit('SET_SUBSCRIPTION_GIFTING_LOADING', value)
  },
  setGiftableMemberships({ commit }, value) {
    commit('SET_GIFTABLE_MEMBERSHIPS', value)
  },
  setSectionState({ commit }, { section, value }) {
    commit('SET_SECTION_STATE', { section, value })
  },
  clearSectionState({ commit }) {
    commit('CLEAR_SECTION_STATE')
  },
  setSectionDrawerOpen({ commit }, { section, value }) {
    commit('SET_SECTION_DRAWER_OPEN', { section, value })
  },
  async fetchGiftableMemberships({ commit }) {
    try {
      commit(
        'SET_GIFTABLE_MEMBERSHIPS',
        (await this.$axios.get(`/api/membership/gifts/`)).data.results
      )
    } catch (e) {
      this.$sentry.captureException(e)
      throw e
    }
  },
}

const getters = {
  subscriptionGiftingLoading: (state) => state.loading,
  giftableMemberships: (state) => state.giftableMemberships,
  visibleMemberships: (state) => state.visibleMemberships,
  sectionState: (state) => state.sectionState,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
